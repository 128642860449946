.landing-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .header {
    background-color: #e74c3c;
    color: white;
    padding: 50px 20px;
    border-bottom: 5px solid #c0392b;
  }
  
  .header h1 {
    font-size: 2.5rem;
  }
  
  .header span {
    color: yellow; /* Highlight 'Lummox' */
  }
  
  .cta-section {
    margin: 40px 0;
  }
  
  .cta-section h2 {
    font-size: 2rem;
    color: #e74c3c;
  }
  
  .cta-button {
    background-color: #e74c3c;
    color: white;
    padding: 12px 24px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #c0392b;
  }
  
  footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    margin-top: 50px;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
  }
  
  .form-group label {
    flex: 1;
    font-size: 1rem;
    margin-right: 10px;
    text-align: right;
  }
  
  .form-group input {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
  
  .submit-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 400px;
  }
  
  .submit-button:hover {
    background-color: #c0392b;
  }
  
  .modal-close-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .modal-close-button:hover {
    background-color: #c0392b;
  }